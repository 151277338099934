import { useState, useContext } from "react";
import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { PayPalButtons } from "@paypal/react-paypal-js";

import { useAuthContext } from "./auth/useAuth";

enum Mode {
  Initial,
  Success,
}

const PaymentDialogContext = React.createContext<{
  openPaymentDialog: Function;
}>({
  openPaymentDialog: () => {},
});

type Plans = "digital" | "digital+print";

const getPlanId = (plan: Plans) => {
  if (plan === "digital") {
    return process.env.NEXT_PUBLIC_DIGITAL_PLAN;
  }

  if (plan === "digital+print") {
    return process.env.NEXT_PUBLIC_DIGITAL_AND_PRESS_PLAN;
  }
};

const PaymentDialogContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { updateUserAdditionalData, user } = useAuthContext();

  const email = user?.email;

  const [mode, setMode] = React.useState(Mode.Initial);
  const [open, setOpen] = React.useState(false);
  const [planId, setPlanId] = React.useState<string>();

  const openPaymentDialog = (plan: string) => {
    setOpen(true);

    // console.log("plan", plan, getPlanId(plan));

    setPlanId(plan);
  };

  const handleClose = () => {
    setMode(Mode.Initial);
    setOpen(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const initial = mode === Mode.Initial;
  const success = mode === Mode.Success;

  return (
    <PaymentDialogContext.Provider value={{ openPaymentDialog }}>
      {children}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          {initial && (
            <>
              <Typography sx={{ marginTop: 2, marginBottom: 3 }} align="center">
                Para fazer o pagamento, clique no botão do PayPal:
              </Typography>

              <Box marginX={2}>
                <PayPalButtons
                  style={{ layout: "horizontal" }}
                  createSubscription={(data, actions) => {
                    return actions.subscription
                      .create({
                        plan_id: planId || "",
                        subscriber: { email_address: email },
                      })
                      .then((orderId) => {
                        // Your code here after create the order
                        return orderId;
                      });
                  }}
                  onApprove={async (data, actions) => {
                    setMode(Mode.Success);
                    // updateUserAdditionalData();
                    return;
                  }}
                />

                {/* <PayPalButton
                  options={{
                    clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
                    locale: "pt_BR",
                    vault: true,
                    currency: "BRL",
                    intent: "subscription",
                  }}
                  createSubscription={(data: unknown, actions: any) => {
                    return actions.subscription.create({
                      plan_id: planId,
                      subscriber: { email_address: email },
                    });
                  }}
                  shippingPreference="NO_SHIPPING"
                  onApprove={(data: unknown, actions: any) => {
                    // Capture the funds from the transaction

                    setIsLoading(true);
                    return actions.subscription
                      .get()
                      .then(function (details: any) {

                        setIsLoading(false);
                        setTimeout(() => {
                          // setMode(Mode.Success);
                        }, 10);
                        // updateUserAdditionalData();
                        console.log("details", { data, details });

                        // OPTIONAL: Call your server to save the subscription
                        return;
                        // return fetch("http://localhost:3001/", {
                        //   method: "post",
                        //   body: JSON.stringify(data),
                        // });
                      })
                      .then(() => {
                        setMode(Mode.Success);
                      });
                  }}
                  // onSuccess={(details: unknown, data: any) => {
                  //   console.log("details", { data, details });
                  //   // setMode(Mode.Success);
                  // }}
                /> */}
              </Box>
            </>
          )}

          {success && (
            <>
              <Typography sx={{ marginTop: 2, marginBottom: 3 }} align="center">
                Pagamento feito com sucesso! Você já pode continuar usando o site.
              </Typography>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </PaymentDialogContext.Provider>
  );
};

const usePaymentDialog = () => {
  const { openPaymentDialog } = useContext(PaymentDialogContext);

  return { openPaymentDialog };
};

export { PaymentDialogContextProvider, usePaymentDialog };
