export type Address = {
  postalCode: string;
  street: string;
  complement: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
};

export type Subscription = {
  status: "CANCELLED" | "ACTIVE";
  planId: string;
  subscriptionId: string;
};

export type User = {
  id: string;
  displayName: string;
  email: string;
  token: string;
  photoURL: string;
};

export type UserAdditionalData = {
  address?: Address;
  subscription?: Subscription;
};

export const mapUserData = (user): User => {
  const { uid, displayName, email, xa, photoURL } = user;

  return {
    id: uid,
    displayName,
    email,
    token: xa,
    photoURL,
  };
};
