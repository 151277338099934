import firebase from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
};

export default function initFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
}

export const fetchSignInMethodsForEmail = ({ email, password }) =>
  firebase
    .auth()
    .fetchSignInMethodsForEmail(email)
    // .signInWithEmailAndPassword(email, password)
    .then((signInMethods) => {
      // const user = userCredential.user;

      // console.log(signInMethods);
      // console.log({ userCredential, user });
      return signInMethods;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(error, { errorCode, errorMessage });
    });

export const signInWithEmailAndPassword = ({ email, password }) =>
  firebase.auth().signInWithEmailAndPassword(email, password);
// .then((userCredential) => {
//   const user = userCredential.user;

//   // console.log({ userCredential, user });
//   return;
// })
// .catch((error) => {
//   const errorCode = error.code;
//   const errorMessage = error.message;
//   console.error(error, { errorCode, errorMessage });
// });

export const createUserWithEmailAndPassword = ({ email, password }) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);
// .then((signInMethods) => {
//   const user = userCredential.user;

//   // firebase.auth().updateProfile();

//   console.log({ userCredential, user }, firebase.auth().updateProfile);
//   return;
// })
// .catch((error) => {
//   const errorCode = error.code;
//   const errorMessage = error.message;
//   console.error(error, { errorCode, errorMessage });
// });

export const sendPasswordResetEmail = ({ email }) => firebase.auth().sendPasswordResetEmail(email);

export const verifyPasswordResetCode = ({ code }) => firebase.auth().verifyPasswordResetCode(code);

export const confirmPasswordReset = ({ code, newPassword }) => firebase.auth().confirmPasswordReset(code, newPassword);
