import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";

import Link from "../components/Link";

const Header = () => {
  const router = useRouter();

  return (
    <Container sx={{ textAlign: "center", paddingBottom: 2 }}>
      <Divider sx={{ marginBottom: 2 }} />

      <Typography gutterBottom>
        Consulte a nossa política de privacidade,{" "}
        <Link href="https://loja.boasemente.com.br/policies/privacy-policy" target="_blank">
          clicando aqui
        </Link>
        .
      </Typography>

      <Typography>
        {/* Nossos outros sites:{" "} */}
        <Link href="href://boasemente.com.br" target="_blank">
          boasemente.com.br
        </Link>
        {/* {" • "}
        <MuiLink href="//seminariodlc.com.br" target="_blank">
          seminariodlc.com.br
        </MuiLink> */}
      </Typography>
    </Container>
  );
};

export default Header;
