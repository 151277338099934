const getErrorMessageForFirebaseError = (code?: string) => {
  if (code === "auth/user-not-found") {
    return "Usuário não encontrado, verifique se o e-mail está correto";
  }

  if (code === "auth/email-already-in-use") {
    return "Este e-mail já está em uso";
  }

  return "Erro ao fazer login, tente novamente ou entre em contato";
};

export default getErrorMessageForFirebaseError;
