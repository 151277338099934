import React, { useEffect } from "react";

import AccountCircle from "@mui/icons-material/AccountCircle";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useRouter } from "next/router";

import { NextLinkComposed } from "../components/Link";
import LoginDialog, { Mode } from "../components/LoginDialog";
import { useAuthContext } from "../utils/auth/useAuth";
const UserBit = ({ component }: { component: React.ReactElement }) => {
  const { user, logout, showLogin, setShowLogin } = useAuthContext();

  const anchorRef = React.useRef<null | HTMLElement>(null);

  const [showMenu, setShowMenu] = React.useState(false);

  const handleToggleShowMenu = () => {
    setShowMenu((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const loginDialogMode = useRouter().query.mode === "resetPassword" ? Mode.verifyPasswordResetCode : undefined;

  useEffect(() => {
    if (Boolean(loginDialogMode)) {
      setShowLogin(true);
    }
  }, [loginDialogMode]);

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setShowMenu(false);
    }
  }

  const loginDialog = (
    <LoginDialog
      open={showLogin}
      onClose={() => {
        setShowLogin(false);
      }}
      initialMode={loginDialogMode}
    />
  );

  return (
    <>
      {user
        ? React.cloneElement(
            component,
            { ref: anchorRef, onClick: handleToggleShowMenu, endIcon: <AccountCircle /> },
            "Minha conta"
          )
        : React.cloneElement(
            component,
            {
              onClick: () => {
                setShowLogin(true);
              },
            },
            "Acessar minha conta"
          )}

      {loginDialog}

      <Popper id="menu-appbar" anchorEl={anchorRef.current} transition disablePortal placement="bottom" open={showMenu}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={showMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleCloseMenu} component={NextLinkComposed} to="/minha-conta#perfil">
                    Perfil
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu} component={NextLinkComposed} to="/minha-conta#assinatura">
                    Assinatura
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu} component={NextLinkComposed} to="/minha-conta#endereco">
                    Meu endereço
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu} component={NextLinkComposed} to="/minha-conta#faturas">
                    Faturas
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      logout();
                      handleCloseMenu();
                    }}
                  >
                    Sair
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default UserBit;
