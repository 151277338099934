import React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";

import UserBit from "./UserBit";
import { NextLinkComposed } from "../components/Link";
const menuItems = [
  { href: "/", label: "Início" },
  { href: "/edicoes", label: "Edições" },
  { href: "/planos", label: "Planos" },
];

const MenuItem = React.forwardRef<HTMLElement, any>(({ href, transition, currentRoute, children, ...props }, ref) => {
  const shouldHighlight = href === currentRoute;

  return (
    <Button
      ref={ref}
      color="primary"
      variant={shouldHighlight ? "contained" : "outlined"}
      disableElevation
      {...(transition === "no" ? {} : { component: NextLinkComposed, to: href })}
      {...props}
    >
      {children}
    </Button>
  );
});

MenuItem.displayName = "MenuItem";

const Header = () => {
  const currentRoute = useRouter().route;

  return (
    <>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <Container
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              "& > *": {
                marginRight: 1,
              },
            }}
          >
            {menuItems.map((item) => (
              <MenuItem key={item.href} href={item.href} currentRoute={currentRoute} sx={{ marginLeft: 1 }}>
                {item.label}
              </MenuItem>
            ))}

            <UserBit
              component={
                <MenuItem href="/minha-conta" transition="no" currentRoute={currentRoute} sx={{ marginLeft: 1 }} />
              }
            />
          </Container>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          background: "url('/images/aaron-burden-149691-unsplash-cut.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          padding: "61px 0",
        }}
      >
        <Container>
          <Typography
            sx={{
              fontFamily: "'Roboto Slab', serif",
              fontSize: "3rem",
              color: "#fff",
              textShadow: "2px 2px 10px #0a0a0a",
              letterSpacing: "normal",
            }}
            variant="h1"
          >
            Revista Leituras Cristãs
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              textShadow: "2px 2px 10px #0a0a0a",
            }}
            variant="h3"
            component="h2"
          >
            Conteúdo cristão para edificação
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Header;
