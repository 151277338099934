import "../global.css";
import "../ck-content.css";

import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppPropsType } from "next/dist/shared/lib/utils";
import { useRouter } from "next/router";

import Footer from "../layout_components/Footer";
import Header from "../layout_components/Header";
import theme from "../src/theme";
import { AuthContextProvider } from "../utils/auth/useAuth";
import { PaymentDialogContextProvider } from "../utils/usePaymentDialog";

// import posthog from "posthog-js";
// import { PostHogProvider } from "posthog-js/react";

// Check that PostHog is client-side (used to handle Next.js SSR)
// if (typeof window !== "undefined") {
//   posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
//     api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
//     // Enable debug mode in development
//     loaded: (posthog) => {
//       if (process.env.NODE_ENV === "development") posthog.debug();
//     },
//     capture_pageview: false, // Disable automatic pageview capture, as we capture manually
//   });
// }

// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";

// const config = {
//   apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
//   authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
//   projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
// };

// if (!firebase.apps.length) {
//   firebase.initializeApp(config);
// }

// Create a client
const queryClient = new QueryClient();

function MyApp(props: AppPropsType) {
  const { Component, pageProps } = props;

  // const router = useRouter();

  // useEffect(() => {
  //   // Track page views
  //   const handleRouteChange = () => posthog?.capture("$pageview");
  //   router.events.on("routeChangeComplete", handleRouteChange);

  //   return () => {
  //     router.events.off("routeChangeComplete", handleRouteChange);
  //   };
  // }, []);

  return (
    //     <>
    //       <title>
    // {process.env.NODE_ENV === "production" ? "" : `(${process.env.APP_ENV}) `}
    // Revista Leituras Cristãs
    //       </title>
    //       {/* <PostHogProvider client={posthog}> */}
    <AppCacheProvider {...props}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <PayPalScriptProvider
              options={{
                clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID || "",
                locale: "pt_BR",
                buyerCountry: "BR",
                intent: "subscription",
                vault: true,
              }}
            >
              <PaymentDialogContextProvider>
                <CssBaseline />
                <Header />
                <Container
                  style={{
                    flexGrow: 1,
                    paddingTop: 32,
                    paddingBottom: 32,
                  }}
                >
                  <Component {...pageProps} />
                </Container>
                <Footer />
              </PaymentDialogContextProvider>
            </PayPalScriptProvider>
          </AuthContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </AppCacheProvider>
    //   {/* </PostHogProvider> */}
    // </>
  );
}

export default MyApp;
