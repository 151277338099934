import firebase from "firebase/app";
import "firebase/firestore";
import initFirebase from "./initFirebase";

initFirebase();

const initFirestore = () => {
  return firebase.firestore();
};

export default initFirestore;
